export const environment = {
    production: false,
    baseHref: '/',
    redirectUri: 'https://photo-capture.amgreetings.com/',
    apiScopeUrl: 'api://380be2b0-4386-4f4a-a8fd-8e97739db03c/access_as_user',
    clientId: 'c8b462bf-2831-4925-8256-6b226113dde3',
    postLogoutRedirectUri: 'https://photo-capture.amgreetings.com/',
    webApi: 'https://photo-capture-api.prod.ag2goservices.amgreetings.com/api/',
    userRolesAuthorization: false,
    tablePaginationSizes: [10, 25, 50, 75, 100, 150, 200],
    pageableAllItems: 99999,
    noLoadingSpinnerHeader: 'loading-spinner-disabled',
    httpRequestChunkSize: 100,
    emojiRegEx: '^[A-Za-z0-9\\s+!@#$%^&*()-–_+=`~\\\]\[{}|\';:/.,?><]*$',
    cloudFrontRequests: '.cloudfront.net/',
    imagesBucket: 'ag2go-photocapture-prod',
    resizedImagesBucket: 'ag2go-photocapture-resized-prod'
};
